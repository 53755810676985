import { render, staticRenderFns } from "./ListErrors.vue?vue&type=template&id=06fe2b79&scoped=true"
import script from "./ListErrors.vue?vue&type=script&lang=ts"
export * from "./ListErrors.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06fe2b79",
  null
  
)

export default component.exports