<template>
  <div class="ibox logs">
    <div class="ibox-title">
      <h2>{{ $t('admin_stats.list_logs.title') }}</h2>
    </div>
    <div :class="'ibox-content p-md' + (listLogsLoading ? ' sk-loading' : '')">

      <div v-if="listLogsLoading" class="sk-spinner sk-spinner-double-bounce">
          <div class="sk-double-bounce1"></div>
          <div class="sk-double-bounce2"></div>
      </div>

      <Select
        v-if="availableAppReferences.length > 0"
        :label="$t('admin_stats.list_logs.app_reference.label')"
        :placeholder="$t('admin_stats.list_logs.app_reference.placeholder')"
        :selectOptions="{
          options: availableAppReferences
        }"
        v-bind:value.sync="form.appReference" 
        :labelAsColumn="true"
        required
      />

      <div class="row">

        <b-pagination
            v-model="currentPage"
            :total-rows="listLogs.length"
            :per-page="perPage"
            aria-controls="my-table"
        ></b-pagination>

      </div>

      <div class="row">
    
        <b-table 
            outlined striped
            :items="listLogs"
            :fields="listLogsFields"
            :per-page="perPage"
            :current-page="currentPage"
            ref="listLogsElement">
        </b-table>

      </div>

      <button class="btn btn-primary" @click="clearLogs()">{{ $t('admin_stats.list_logs.clear-button') }}</button>

    </div>
  </div>
</template>

<style scoped>
  
</style>



<script lang="ts">
import { toRefs, Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';

import Select from '@fwk-client/components/panels/input/Select.vue';
import * as api from '@fwk-client/utils/api';
import { languagesTypes } from '@fwk-client/store/types';

export default defineComponent({
  props: {
      
  },
  components: {
      Select
  },
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();

    const listLogsElement:Ref<HTMLElement|null> = ref(null);
    const listLogsLoading:Ref<boolean> = ref(false);

    const perPage:number = 10;
    const currentPage:Ref<number> = ref(1);

    const availableAppReferences = [
      "igotweb-portal",
      "vigneron-online"
    ]

    const form = reactive({
      appReference: availableAppReferences[0],
    })

    const listLogsFields = [
      {
        key: "dateTime"
      },
      {
        key: "description"
      }
    ];

    const listLogs:Ref<any[]> = ref([]);

    const updateListLogs = () => {
      // In case no app is selected
      if(!form.appReference) {
        listLogs.value = [];
      }

      var options:api.ApiVueOptions =  {
        app: app
      }

      var input = {
        appReference: form.appReference
      }

      listLogsLoading.value = true;

      return api.postAPI('/api/admin/stats/logs/list', input, options).then((response:any) => {
        listLogsLoading.value = false;
        // We check the additional fields
        if(response.options) {
          
        }
        if(response.logs) {  
          listLogs.value = response.logs;
        }
        else {
          listLogs.value = [];
        }
      })
    }
    updateListLogs();

    const clearLogs = () => {
      var options:api.ApiVueOptions =  {
        app: app
      }
      var input = {
        appReference: form.appReference
      }
      listLogsLoading.value = true;
      return api.postAPI('/api/admin/stats/logs/clear', input, options).then((response:any) => {
        listLogsLoading.value = false;
        // We check the additional fields
        if(response.cleared) {  
          updateListLogs();
        }
      })
    }

    watch(
      () => form.appReference,
      (val:any, oldVal:any) => {
        // @ts-ignore
        updateListLogs()
      },
      { deep: true }
    )

    return {
      listLogsFields,
      listLogsLoading,
      listLogs,
      clearLogs,
      form,
      availableAppReferences,
      perPage,
      currentPage,
      listLogsElement
    }
  }
});
</script>