<template>
  <div class="ibox errors">
    <div class="ibox-title">
      <h2>{{ $t('admin_stats.list_errors.title') }}</h2>
    </div>
    <div :class="'ibox-content p-md' + (listErrorsLoading ? ' sk-loading' : '')">

      <div v-if="listErrorsLoading" class="sk-spinner sk-spinner-double-bounce">
          <div class="sk-double-bounce1"></div>
          <div class="sk-double-bounce2"></div>
      </div>

      <Select
        v-if="availableAppReferences.length > 0"
        :label="$t('admin_stats.list_errors.app_reference.label')"
        :placeholder="$t('admin_stats.list_errors.app_reference.placeholder')"
        :selectOptions="{
          options: availableAppReferences
        }"
        v-bind:value.sync="form.appReference" 
        :labelAsColumn="true"
        required
      />

      <div class="row">

        <b-pagination
            v-model="currentPage"
            :total-rows="listErrors.length"
            :per-page="perPage"
            aria-controls="my-table"
        ></b-pagination>

      </div>

      <div class="row">
    
        <b-table 
            outlined striped
            :items="listErrors"
            :fields="listErrorsFields"
            :per-page="perPage"
            :current-page="currentPage"
            ref="listErrorsElement">
        </b-table>

      </div>

      <button class="btn btn-primary" @click="clearErrors()">{{ $t('admin_stats.list_errors.clear-button') }}</button>

    </div>
  </div>
</template>

<style scoped>
  
</style>



<script lang="ts">
import { toRefs, Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';

import Select from '@fwk-client/components/panels/input/Select.vue';
import * as api from '@fwk-client/utils/api';
import { languagesTypes } from '@fwk-client/store/types';

export default defineComponent({
  props: {
      
  },
  components: {
      Select
  },
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();

    const listErrorsElement:Ref<HTMLElement|null> = ref(null);
    const listErrorsLoading:Ref<boolean> = ref(false);

    const perPage:number = 10;
    const currentPage:Ref<number> = ref(1);

    const listErrors:Ref<any[]> = ref([]);

    const availableAppReferences = [
      "igotweb-portal",
      "vigneron-online"
    ]

    const form = reactive({
      appReference: availableAppReferences[0],
    })

    const listErrorsFields = [
        {
          key: "dateTime"
        },
        {
          key: "code"
        },
        {
          key: "message"
        },
        {
          key: "stack"
        },
        {
          key: "user"
        },
        {
          key: "route"
        }
      ];

      const updateListErrors = () => {
        // In case no app is selected
        if(!form.appReference) {
          listErrors.value = [];
        }

        var options:api.ApiVueOptions =  {
          app: app
        }

        var input = {
          appReference: form.appReference
        }

        listErrorsLoading.value = true;

        return api.postAPI('/api/admin/stats/errors/list', input, options).then((response:any) => {
          listErrorsLoading.value = false;
          // We check the additional fields
          if(response.options) {
            
          }
          if(response.errors) {  
            listErrors.value = response.errors;
          }
          else {
            listErrors.value = [];
          }
        })
      }
      updateListErrors();

      const clearErrors = () => {
        var options:api.ApiVueOptions =  {
          app: app
        }
        var input = {
          appReference: form.appReference
        }
        listErrorsLoading.value = true;
        return api.postAPI('/api/admin/stats/errors/clear', input, options).then((response:any) => {
          listErrorsLoading.value = false;
          // We check the additional fields
          if(response.cleared) {  
            updateListErrors();
          }
        })
      }

      watch(
        () => form.appReference,
        (val:any, oldVal:any) => {
          updateListErrors();
        },
        { deep: true }
      )

      return {
        listErrorsFields,
        listErrorsLoading,
        listErrors,
        clearErrors,
        form,
        availableAppReferences,
        perPage,
        currentPage,
        listErrorsElement
      }
  }
});
</script>