<template>
  <section id="content">
    <PageTitle title="Stats"></PageTitle>
    <div class="wrapper wrapper-content animated fadeInRight">

      <div class="row">
        <div class="col-lg-12">
          <ListTransactions></ListTransactions>
          <ListLogs></ListLogs>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <ListErrors></ListErrors>
        </div>
      </div>

    </div>

  </section>
</template>

<style scoped>
  
</style>



<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component } from '@fwk-node-modules/vue-property-decorator';
import { mixins } from '@fwk-node-modules/vue-class-component';
import GenericPage from '@fwk-client/components/mixins/GenericPage.vue';
import * as api from '@fwk-client/utils/api';
import { messagesTypes, MessageTypes } from '@fwk-client/store/types';
import PageTitle from '../../panels/PageTitle.vue';
import ListTransactions from '../../panels/admin/eurofiscalis/workers/ListTransactions.vue';
import ListLogs from '../../panels/admin/stats/ListLogs.vue';
import ListErrors from '../../panels/admin/stats/ListErrors.vue';

@Component({
  components: { 
    PageTitle,
    ListTransactions,
    ListLogs,
    ListErrors
  }
})
export default class Stats extends mixins(GenericPage) {
  
}
</script>